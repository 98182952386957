<template>
  <div>

    <!-- Filter Section -->
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
          <!-- Period -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.reportCashBank.singular.period')"
              label-for="period"
            >
              <v-select
                id="period"
                class="select-size-sm"
                v-model="form.period"
                :options="LOV.period"
                :reduce="field => field.value"
                @input="handlePeriod"
              />
            </b-form-group>
          </b-col>

          <!-- Date Start -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.reportCashBank.singular.dateStart')"
              label-for="date-start"
            >
              <flat-pickr
                id="date-start"
                :config="dateFormat"
                v-model="form.dateStart"
                class="form-control form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Date End -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('apps.reportCashBank.singular.dateEnd')"
              label-for="date-start"
            >
              <flat-pickr
                id="date-end"
                :config="dateFormat"
                v-model="form.dateEnd"
                class="form-control form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Transaction Type -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.masterDataCashbank.moduleName')"
              label-for="cashbank-code"
            >
              <v-select
                id="cashbank-account"
                class="select-size-sm"
                ref="firstFocusSelectInput"
                v-model="form.cashbankId"
                :options="LOV.cashbankCodeAccounts"
                :reduce="field => field.id"
                label="label"
              />
            </b-form-group>
          </b-col>

          <!-- Cabang -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.reportCashBank.singular.cabang')"
              label-for="cabang"
            >
              <v-select
                id="cabang"
                class="select-size-sm"
                label="brand"
                v-model="form.cabang"
                :options="LOV.cabangLOV"
                :reduce="field => field.id"
              />
            </b-form-group>
          </b-col>

          <!-- Button Filter -->
          <b-col cols="12" md="3">
            <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
              {{ $t('globalActions.find') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <div class="mt-1 mb-1 d-flex justify-content-end">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <!-- <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button> -->
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

      <b-card-body class="text-center">
        <b-card-title>{{ companyName }}</b-card-title>
        <b-card-sub-title>
          <div>{{ $t('apps.reportCashBank.singular.cashBankReport') }}</div>
          <div class="mt-sm-1">{{ periodReportText }}</div>
        </b-card-sub-title>
      </b-card-body>

      <div v-if="loading" class="my-2 text-center text-primary d-flex flex-column justify-content-center">
            <div class="mb-1 text">
              <b-spinner class="align-middle"/>
            </div>
            <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
      </div>

<!-- div looping -->
<div v-for="(item, index) in itemLists" :key="index">
  <div id="tables">
      <div class="invoice-number-date mt-md-0 mt-2">
        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                  <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Cash Bank Code
                          </td>
                          <td><span class="font-weight-bold"> : {{itemLists[index].header.name}}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                             Account 
                          </td>
                          <td><span class="font-weight-bold"> : {{itemLists[index].header.bank_account_number}}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Branch 
                          </td>
                          <td><span class="font-weight-bold"> : {{itemLists[index].header.branch}}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Type 
                          </td>
                          <td><span class="font-weight-bold"> : {{itemLists[index].header.type}}</span></td>
                        </tr>
                      </tbody>
                    </table>
        </div>
      </div>
      <b-table
        id="refListTable"
        ref="refListTable"
        no-provider-paging
        no-provider-filtering
        :filter-included-fields="columnToBeFilter"
        :fields="tableColumns"
        :items="itemLists[index].details"
        :filter="searchQuery"
        responsive
        primary-key="id"
        show-empty
        :busy="isBusy"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <template #table-busy>
          <div class="my-2 text-center text-primary d-flex flex-column justify-content-center">
            <div class="mb-1 text">
              <b-spinner class="align-middle"/>
            </div>
            <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
          </div>
        </template>

        <!-- Column: date -->
        <template #cell(date)="{ item }">
          <div v-if="item.date !== '' ">
             <span class="d-block text-nowrap">
            {{ formatDatepicker(item.date) }}
          </span>
          </div>
          <div v-else>
              <!-- kosong -->
          </div>
        </template>

        <!-- Column: remaks -->
        <template #cell(remark)="{ item }">
            <div v-if="(item.remark !== 'Total') &&  (item.remark !== 'Saldo Awal')">
             <span class="font-weight-bold">
                {{ item.remark }}
             </span>
          </div>
          <div v-else>
            <span class="font-weight-bold d-block text-nowrap">
              <i> <b> {{ item.remark }} </b> </i>
             </span>
          </div>
        </template>

        <!-- Column: debit -->
        <template #cell(debit)="{ item }">
          <div v-if="item.remark !== 'Total' ">
             <span class="font-weight-bold">
                {{ formatCurrency(item.debit) }}
             </span>
          </div>
          <div v-else>
            <span class="font-weight-bold">
               <i> <b> {{ formatCurrency(item.debit) }} </b> </i>
             </span>
          </div>
        </template>

        <!-- Column: kredit -->
        <template #cell(kredit)="{ item }">
          <div v-if="item.remark !== 'Total' ">
             <span class="font-weight-bold">
                <div v-if="(item.kredit !== null) && (item.kredit !== 0)"> ({{ formatCurrency(item.kredit * -1) }}) </div>
                <div v-else> {{ formatCurrency(item.kredit) }} </div>
             </span>
          </div>
          <div v-else>
            <span class="font-weight-bold">
               <i> <b> ({{ formatCurrency(item.kredit * -1) }}) </b> </i>
             </span>
          </div>
        </template>

        <!-- Column: beginning balance -->
        <template #cell(beginning_balance)="{ item }">
          <div v-if="(item.remark !== 'Total') && (item.remark !== 'Saldo Awal')">
              <div v-if="(item.beginning_balance < 0) && (item.beginning_balance !== 0)">
                <span class="font-weight-bolder d-block text-nowrap text-danger">
                    ({{ formatCurrency(item.beginning_balance * -1) }})
                </span>
              </div>
              <div v-else>
                    {{ formatCurrency(item.beginning_balance) }}
              </div>
          </div>
          <div v-else>
              <div v-if="(item.beginning_balance < 0) &&  (item.beginning_balance !== 0)">
                  <span class="font-weight-bolder d-block text-nowrap text-danger">
                     <i> <b> ({{ formatCurrency(item.beginning_balance * -1) }}) </b> </i>
                  </span>
              </div>
              <div v-else>
                    <i> <b> {{ formatCurrency(item.beginning_balance) }} </b> </i>
              </div>
          </div>
        </template>
      </b-table>
      </div>
    </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItemButton,
  BTable,
  BSpinner
} from 'bootstrap-vue'

import { format, getMonth, startOfMonth, endOfMonth, subMonths, addMonths, subYears, parse } from 'date-fns'
import { ref, onMounted } from '@vue/composition-api'
import { formatCurrency, formatDatepicker } from '@/utils/formatter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import useListTable from '@/comp-functions/useListTable'
import useFormResource from '@/comp-functions/useFormResource'
import storestate from '@/store/index'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BTable,
    BSpinner,
    vSelect,
    flatPickr
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const form = ref({
      period: 'thisMonth',
      dateStart: '',
      dateEnd: '',
      cashbankId: '',
      accountId: '',
      cabang: null
    })
    const loading = ref(false)
    const periodReportText = ref()

    // export format
    const formats = ['xlsx', 'csv', 'txt']

    const dateFormat = ref({ dateFormat: 'd/m/Y' })

    const LOV = ref({
      period: [
        { value: 'thisMonth', label: 'This Month' },
        { value: 'previousMonth', label: 'Previous Month' },
        { value: 'thisYear', label: 'This Year' },
        { value: 'previousYear', label: 'Previous Year' }
      ],
      cabangLOV: [],
      // cashbankAccounts: [],
      cashbankCodeAccounts: [],
    })

    // Table Handlers
    const tableColumns = [
      { key: 'date', label: 'Date' },
      { key: 'transaksi_no', label: 'Transactions No' },
      { key: 'remark', label: 'Remaks' },
      { key: 'debit', label: 'Debit', thClass: 'text-right', tdClass: 'text-right' },
      { key: 'kredit', label: 'Kredit', thClass: 'text-right', tdClass: 'text-right' },
      { key: 'beginning_balance', label: 'Beginning Balance', thClass: 'text-right', tdClass: 'text-right' },
    ]

    // Table Handlers
    const columnToBeFilter = [
      'code',
      'date',
      'reference_code'
    ]

    const handlePeriod = () => {
      const formatString = 'dd/MM/yyyy'
      const thisMonth = new Date()
      const whatMonth = getMonth(thisMonth) + 1
      const previousMonth = subMonths(thisMonth, 1)
      const firstMonthOfYear = subMonths(thisMonth, whatMonth - 1)
      const lastMonthOfYear = addMonths(thisMonth, (whatMonth - 12) * -1)
      const firstMonthOfPreviousYear = subYears(firstMonthOfYear, 1)
      const lastMonthOfPreviousYear = subYears(lastMonthOfYear, 1)

      if (form.value.period === 'thisMonth') {
        form.value.dateStart = format(startOfMonth(thisMonth), formatString)
        form.value.dateEnd = format(endOfMonth(thisMonth), formatString)
      } else if (form.value.period === 'previousMonth') {
        form.value.dateStart = format(startOfMonth(previousMonth), formatString)
        form.value.dateEnd = format(endOfMonth(previousMonth), formatString)
      } else if (form.value.period === 'thisYear') {
        form.value.dateStart = format(startOfMonth(firstMonthOfYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfYear), formatString)
      } else {
        form.value.dateStart = format(startOfMonth(firstMonthOfPreviousYear), formatString)
        form.value.dateEnd = format(endOfMonth(lastMonthOfPreviousYear), formatString)
      }
    }

    const updatePeriodText = () => {
      const dateStart = parse(form.value.dateStart, 'dd/MM/yyyy', new Date())
      const dateEnd = parse(form.value.dateEnd, 'dd/MM/yyyy', new Date())
      const formatStartDate = format(dateStart, 'd MMMM yyyy')
      const formatEndDate = format(dateEnd, 'd MMMM yyyy')
      periodReportText.value = `${formatStartDate} to ${formatEndDate}`
    }

    const companyActive = storestate.state.user.currentCompany

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const { get } = useFormResource({ url: '' })

    // const getCashbankAccount = async () => {
    //   LOV.value.cashbankAccounts = await get({ url: 'master/accounts/child?account_subtype_code=CASHBANK'})
    // }

    const cabangLov = async () => {
      LOV.value.cabangLOV = await get({ url: 'value/getOffice'})
    }


    const getCashbankCode = async () => {
      LOV.value.cashbankCodeAccounts = await get({ url: 'value/mapping-cashbank-per-users'})
    }

    onMounted(async () => { 
    if(!storestate.state.user.is_national){
        form.value.cabang = storestate.state.user.companies[0].office.id
    }else{
      form.value.cabang = 225
    }
      // getCashbankAccount()
      cabangLov()
      handlePeriod()
      getCashbankCode()
      

      const params = [
        `dateStart=${form.value.dateStart}`,
        `dateEnd=${form.value.dateEnd}`,
        `cabang=${form.value.cabang ?? ''}`,
        `accountId=${form.value.accountId}`,
        `cashbankId=${form.value.cashbankId}`
      ]

      const url = `reports/cashbankAndBankBranchReport?${ params.join('&') }`
      await fetchLists(url)
      updatePeriodText()
    })

    return {
      form,
      periodReportText,
      formats,
      LOV,
      dateFormat,
      tableColumns,
      columnToBeFilter,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      companyActive,
      fetchLists,
      handlePeriod,
      updatePeriodText,
      formatCurrency,
      formatDatepicker,
      loading
    }
  },
  methods: {
    getReport () {
      this.loading = true
      const params = [
        `dateStart=${this.form.dateStart}`,
        `dateEnd=${this.form.dateEnd}`,
        `cabang=${this.form.cabang ?? ''}`,
        `accountId=${this.form.accountId}`,
        `cashbankId=${this.form.cashbankId}`
      ]

      const url = `reports/cashbankAndBankBranchReport?${ params.join('&') }`
      this.loading = false
      this.fetchLists(url)

      this.updatePeriodText()

    },
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list  = this.itemLists
        let dataFix = []
        
        for (let index = 0; index < list.length; index++) {
          const dataHeader1 = {
            beginning_balance: '',
            date: list[index].header.name,
            debit: '',
            kredit: '',
            remark:'',
            transaksi_no: ''
          }
          const dataHeader2 = {
            beginning_balance: '',
            date: list[index].header.bank_account_number,
            debit: '',
            kredit: '',
            remark:'',
            transaksi_no: ''
          }
          const dataHeader3 = {
            beginning_balance: '',
            date: list[index].header.branch,
            debit: '',
            kredit: '',
            remark:'',
            transaksi_no: ''
          }
          const dataHeader4 = {
            beginning_balance: '',
            date: list[index].header.type,
            debit: '',
            kredit: '',
            remark:'',
            transaksi_no: ''
          }
          dataFix.push (dataHeader1,dataHeader2,dataHeader3,dataHeader4)

          for (let i = 0; i < list[index].details.length; i++) {
            dataFix.push (list[index].details[i])
          }
        }
        
        const headerTitle = this.tableColumns.map(column => column.label)
        const headerVal   = this.tableColumns.map(column => column.key)
     
        const data = this.formatJson(headerVal, dataFix)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            [this.$t('apps.reportCashBank.singular.cashBankReport'), '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 5, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 5, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 5, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 5, r: 3 } }
          ],
          filename: `${this.$t('apps.reportCashBank.singular.cashBankReport')} ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  #tables {
    width: inherit;
    margin: 0 250px 50px 160px;
    padding: 20px 0;

    thead {
      th {
        border-top: 2px solid #ebe9f1;
      }
      th:first-child {
        border-left: 2px solid #ebe9f1;
        border-radius: 4px;
      }

      th:last-child {
        border-right: 2px solid #ebe9f1;
        border-radius: 4px;
      }
    }

    td:first-child {
      border-left: 2px solid #ebe9f1;
    }
    td:last-child {
      border-right: 2px solid #ebe9f1;
    }

    tbody {
      tr:last-child {
        border-bottom: 2px solid #ebe9f1;
      }
    }

  }

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
